import React, {useState} from "react";
import {HiChevronUp, HiChevronDown} from "react-icons/hi";
import downloadicon from "../assets/downloadicon.svg";

export const Card = ({children}) => {
    return (
        <>
            <div className="bg-white rounded-lg p-6 card">
                {children}
            </div>
        </>
    );
};

export const FullCard = ({children}) => {
    return (
        <>
            <div className="overflow-y-auto h-[90vh] duration-300 bg-white rounded-t-lg p-6">
                {children}
            </div>
        </>
    );
};

export const WhiteCard = ({children}) => {
    return (
        <>
            <div className="customScrollbar duration-300 bg-white rounded-t-lg">
                {children}
            </div>
        </>
    );
};

export const Spinner = () => {
    return (
        <div className="object-none object-center justify-center my-10">
            <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-2 border-purple"></div>
        </div>
    );
};

export const Select = ({label, value, onChange, dropdownData}) => {
    return (
        <>
            <select
                value={value}
                onChange={onChange}
                className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-purple focus:border-purple block w-full p-2.5"
            >
                {dropdownData.map((x, i) => (
                    <option
                        className={"hover:bg-[#D2CFF1]"}
                        value={x.text}
                        label={x.text}
                    >
                        {x.text}
                    </option>
                ))}
            </select>
        </>
    );
};
export const IconButton = ({onClick, children}) => {
    return (
        <button
            className={
                "bg-white hover:bg-light-purple p-2 tracking-wider leading-5 rounded-lg text-lg text-white font-bold font-heading"
            }
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export const Button = ({
                           size = "medium",
                           onClick,
                           children,
                           radius = "lg",
                           disabled,
                       }) => {
    if (size === "large")
        return (
            <button
                className={`bg-[#766AFF] hover:bg-[#544AC0] px-8 py-4 tracking-wider leading-5 rounded-${radius} text-lg text-white font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "small")
        return (
            <button
                className={`bg-[#23479F] hover:bg-[#544AC0] px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm text-white font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "outline")
        return (
            <button
                className={`border-2 border-[#23479F] bg-white px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm text-[#23479F] font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "download")
        return (
            <button
                className={`border-2 border-[#23479F] bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                <div className="flex">
                    {children}
                    <img
                        src={downloadicon}
                        alt=""
                        width={"15px"}
                        height={"15px"}
                        className="ml-2"
                    />
                </div>
            </button>
        );
    else if (size === "white")
        return (
            <button
                className={`border-2 bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                <div className="flex">
                    {children}
                </div>
            </button>
        );
    else
        return (
            <button
                className={`bg-[#23479F] hover:bg-[#544AC0] px-6 py-3 tracking-wider leading-5 rounded-${radius} text-base text-white font-bold font-heading`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
};

export const Slider = ({min, max, step, value, onChange}) => {
    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                className="orangeColor mt-2 w-full bg-[#EBEAFD] h-1 rounded-full focus:outline-none"
            />
            <div className="flex justify-between w-full">
                <span>{min}</span>
                <span>{max}</span>
            </div>
        </>
    );
};

export const RadioButton = ({
                                checked,
                                type,
                                value,
                                name,
                                onChange,
                                id,
                                label,
                                htmlFor,
                                customClassName,
                            }) => {
    return (
        <>
            <input
                checked={checked}
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                id={id}
                className={`w-4 h-4 text-orange-500 bg-medium-gray border-medium-gray mx-2 ${customClassName}`}
            />
            <label className="text-gray-700" htmlFor={htmlFor}>{label}</label>
        </>
    );
};

export const NewText = ({text}) => {
    const startIndex = 1;
    const modifiedString = text.substring(startIndex);
    const newText = modifiedString.split("- ").map((str, i) => (
        <li className="list-disc" key={i}>
            {str}
        </li>
    ));
    return newText;
};

export const Accordion = ({children, index}) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Card>
                <div onClick={toggleAccordion}>
                    {isOpen ? (
                        <div className="flex justify-end items-center">
                            <HiChevronDown
                                style={{fontSize: "40px"}}
                                className="cursor-pointer"
                            />
                        </div>
                    ) : (
                        <span>
                            <div className="flex justify-between items-center">
                                <div className="subtitle my-5 text-left px-[2%]">
                                    {/* Display the question number dynamically */}
                                    <span className="border-2 border-black py-2 px-4 rounded-full mr-4">
                                        {index + 1}
                                    </span>{" "}
                                    Asked Question
                                </div>
                                <HiChevronUp
                                    style={{fontSize: "40px"}}
                                    className="cursor-pointer"
                                />
                            </div>
                        </span>
                    )}
                </div>
                {isOpen && <div>{children}</div>}
            </Card>
        </div>
    );
};
