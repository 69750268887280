import React, {useEffect, useRef, useState} from "react";
import {Button, Spinner} from "../components/CommonComponents";
import {getRecommendedProtocol, getSimilarProtocols} from "../api/serverAPI";
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";
import ReactJsAlert from "reactjs-alert";
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver';
import {Document, Packer, Paragraph, TextRun, HeadingLevel} from 'docx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import htmlToDocx from 'html-to-docx';
import { ChevronDown, ChevronUp } from 'lucide-react';
import {protocolData} from "../components/Mapping";

const Search = ({ onSectionClick }) => {
    const [drugNames, setDrugNames] = useState([""]);
    const [protocolNumbers, setProtocolNumbers] = useState([""]);
    const [error, setError] = useState("");
    const [searchResults, setSearchResults] = useState(null); // Track search results
    const [recommendedResults, setRecommendedResults] = useState(null); // Track search results
    const [test, setTest] = useState(null); // Track search results
    const navigate = useNavigate();
    const [, setCookie, removeCookie] = useCookies(['apiResponse']);
    const [alertStatus, setAlertStatus] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [isEditable, setIsEditable] = useState({}); // Track editable state per accordion
    const pdfContentRef = useRef(); // Reference to the content that will be turned into a PDF
    const [loading, setLoading] = useState(false);
    const contentRef = useRef();
    const [numStages, setNumStages] = useState(0); // State to hold the number of stages
    const [stageInputs, setStageInputs] = useState([]);
    const [compoundName, setCompoundName] = useState('');
    const [moa, setMoa] = useState('');
    const [leaderContact, setLeaderContact] = useState('');
    const [acronym, setAcronym] = useState('');
    const [protocolNumber, setProtocolNumber] = useState('');
    const [indNumber, setIndNumber] = useState('');
    const [phase, setPhase] = useState('');
    const [randomized, setRandomized] = useState('');
    const [randomizedRatio, setRandomizedRatio] = useState('');
    const [population, setPopulation] = useState('');
    const [diseaseCondition, setDiseaseCondition] = useState('');
    const [blinding, setBlinding] = useState('');
    const [usGlobal, setUsGlobal] = useState('');
    const [numArms, setNumArms] = useState('');
    const [comparator, setComparator] = useState('');
    const [lengthOfCycle, setLengthOfCycle] = useState('');
    const [numCycles, setNumCycles] = useState('');
    const [estDurationStudy, setEstDurationStudy] = useState('');
    const [estDurationIntervention, setEstDurationIntervention] = useState('');
    const [approxParticipants, setApproxParticipants] = useState('');
    const [primaryObjectives, setPrimaryObjectives] = useState('');
    const [secondaryObjectives, setSecondaryObjectives] = useState('');
    const [generatedTitle, setGeneratedTitle] = useState('');
    const [selectedSection, setSelectedSection] = useState("Protocol Summary");
    const [activeTab, setActiveTab] = useState("Recommended Protocol");
    const [showSimilarProtocols, setShowSimilarProtocols] = useState(false);
    const [showFDAGuidelines, setShowFDAGuidelines] = useState(false);
    const [sideBarCollapsed, setSideBarCollapsedCollapsed] = useState(false); // State to track if panes are collapsed
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [expandedSections, setExpandedSections] = useState(new Set());
    const [transformedResponse, setTransformedResponse] = useState(null);

    console.log(transformedResponse)

    const handleSectionClick = (section) => {
        setSelectedSection(section);
        setActiveTab("Recommended Protocol"); // Default to first tab when a new section is clicked
    };

    const handleCompoundNameChange = (e) => setCompoundName(e.target.value);
    const handleMoaChange = (e) => setMoa(e.target.value);
    const handleLeaderContactChange = (e) => setLeaderContact(e.target.value);
    const handleAcronymChange = (e) => setAcronym(e.target.value);
    const handleGeneratedProtocolNumChange = (e) => setProtocolNumber(e.target.value);
    const handleIndChange = (e) => setIndNumber(e.target.value);
    const handlePhase = (e) => setPhase(e.target.value);
    const handleRandomized = (e) => setRandomized(e.target.value);
    const handleRandomizedRatio = (e) => setRandomizedRatio(e.target.value);
    const handlePopulation = (e) => setPopulation(e.target.value);
    const handleDiseaseCondition = (e) => setDiseaseCondition(e.target.value);
    const handleOpenLabel = (e) => setBlinding(e.target.value);
    const handleUsGlobal = (e) => setUsGlobal(e.target.value);
    const handleNumberOfArms = (e) => setNumArms(e.target.value);
    const handleComparator = (e) => setComparator(e.target.value);
    const handleLengthOfCycle = (e) => setLengthOfCycle(e.target.value);
    const handleNumberOfCycles = (e) => setNumCycles(e.target.value);
    const handleEstimatedDurationOfStudy = (e) => setEstDurationStudy(e.target.value);
    const handleEstimatedDurationOfIntervention = (e) => setEstDurationIntervention(e.target.value);
    const handleApproximateNumberOfParticipants = (e) => setApproxParticipants(e.target.value);
    const handlePrimaryObjectives = (e) => setPrimaryObjectives(e.target.value);
    const handleSecondaryObjectives = (e) => setSecondaryObjectives(e.target.value);
    const handleGeneratedTitle = (e) => setGeneratedTitle(e.target.value);

    const handleFDAGuidelinesDownload = (section) => {
        const fileUrl = `${process.env.PUBLIC_URL}/fda-guidelines/${section.replace(/[,:\-\u2013]/g, '')}.pdf`;
        window.open(fileUrl, '_blank');
    };

    const handleSimilarProtocolDownload = (section) => {
        const fileUrl = `${process.env.PUBLIC_URL}/protocols/Keytruda_protocol.pdf`;
        window.open(fileUrl, '_blank');
    };

    const toggleSection = (sectionPath, e) => {
        // e.stopPropagation(); // Prevent the section click handler from firing
        setExpandedSections(prev => {
            const next = new Set(prev);
            if (next.has(sectionPath)) {
                next.delete(sectionPath);
            } else {
                next.add(sectionPath);
            }
            return next;
        });
    };

    const handleClick = (sectionTitle) => {
        setSelectedSection(sectionTitle);
        if (onSectionClick) {
            onSectionClick(sectionTitle);
        }
    };

    const renderSection = (section, index, parentNumber = "") => {
        const currentNumber = parentNumber ? `${parentNumber}.${index + 1}` : `${index + 1}`;
        const hasSubsections = section.subsections && section.subsections.length > 0;
        const sectionPath = `section-${currentNumber}`;
        const isExpanded = expandedSections.has(sectionPath);
        const isSelected = selectedSection === section.title;

        return (
            <li key={currentNumber} className="mb-2">
                <div
                    className={`flex items-center cursor-pointer p-2 rounded hover:bg-[#1D3C86] hover:text-white transition-colors ${
                        isExpanded ? 'bg-[#1D3C86] text-white' : 'text-white'
                    }`}
                    onClick={() => handleClick(section.title)}
                >
                    <span className="flex-grow">{currentNumber}. {section.title}</span>
                    {hasSubsections && (
                        <button
                            className="ml-2 focus:outline-none"
                            onClick={(e) => toggleSection(sectionPath, e)}
                        >
                            {isExpanded ? (
                                <ChevronUp className="h-4 w-4" />
                            ) : (
                                <ChevronDown className="h-4 w-4" />
                            )}
                        </button>
                    )}
                </div>
                {hasSubsections && isExpanded && (
                    <ul className="ml-6 mt-2">
                        {section.subsections.map((subsection, subIndex) =>
                            renderSection(subsection, subIndex, currentNumber)
                        )}
                    </ul>
                )}
            </li>
        );
    };

    const handleDrugChange = (index, event) => {
        const newDrugNames = [...drugNames];
        newDrugNames[index] = event.target.value;
        setDrugNames(newDrugNames);
    };

    const handleProtocolChange = (index, event) => {
        const newProtocolNumbers = [...protocolNumbers];
        newProtocolNumbers[index] = event.target.value;
        setProtocolNumbers(newProtocolNumbers);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useEffect(() => {
        const initializeStages = () => {
            const stages = {};
            for (let i = 1; i <= numStages; i++) {
                stages[`Stage ${i}`] = ''; // Initialize each stage with an empty string
            }
            setStageInputs(stages); // Set the initialized stages
        };

        initializeStages();
    }, [numStages]); // This effect will run whenever numStages changes

    // Handle changes in the number of stages
    const handleNumStages = (e) => {
        const value = parseInt(e.target.value) || 0;
        setNumStages(value); // Update the number of stages
    };

    // Handle changes in each dynamically created stage input
    const handleStageChange = (stageKey, e) => {
        setStageInputs({
            ...stageInputs,
            [stageKey]: e.target.value, // Update the value for the specific stage key
        });
    };

    const addDrugField = () => {
        setDrugNames([...drugNames, ""]);
    };

    const addProtocolField = () => {
        setProtocolNumbers([...protocolNumbers, ""]);
    };

    const handleBack = () => {
        setSearchResults(null);
        setDrugNames([""]);
        setProtocolNumbers([""]);
    };

    // Exception words to keep in lowercase
    const exceptions = new Set(["of", "an", "the", "a", "for", "and", "to", "in", "on", "at", "with"]);

    // Helper function to convert a string to title case with exceptions
    const titleCaseKey = (key) => {
        return key.split(" ").map((word, index) => {
            // Capitalize if not an exception or if it's the first word
            if (!exceptions.has(word) || index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word.toLowerCase();
        }).join(" ");
    };

    // Function to transform the response array by converting keys to title case
    const transformResponseKeys = (response) => {
        return response.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                let titleCasedKey = titleCaseKey(key);
                titleCasedKey = titleCasedKey.replace("Trail", "Trial");
                newItem[titleCasedKey] = item[key];
            });
            return newItem;
        });
    };

    // // Flatten `protocolData` into an array of titles (with path information)
    // function flattenProtocolData(data, parentTitle = "") {
    //     const flattenedTitles = [];
    //
    //     data.forEach(item => {
    //         const fullTitle = parentTitle ? `${parentTitle} > ${item.title}` : item.title;
    //         flattenedTitles.push(fullTitle);
    //
    //         // Recursively process subsections, if they exist
    //         if (item.subsections) {
    //             flattenedTitles.push(...flattenProtocolData(item.subsections, fullTitle));
    //         }
    //     });
    //
    //     return flattenedTitles;
    // }
    //
    // // Create a normalized map of protocol titles
    // function createTitleMap(flattenedTitles) {
    //     return new Map(flattenedTitles.map(title => [titleCase(title), title]));
    // }

    // // Normalize API keys to match the title map
    // function normalizeApiKeys(apiData, titleMap) {
    //     return apiData.map(item => {
    //         const normalizedItem = {};
    //
    //         for (const [key, value] of Object.entries(item)) {
    //             const normalizedKey = titleMap.get(key) || key;
    //             normalizedItem[normalizedKey] = value;
    //         }
    //
    //         return normalizedItem;
    //     });
    // }

    // const flattenedTitles = flattenProtocolData(protocolData);
    // const titleMap = createTitleMap(flattenedTitles);

    const handleSearch = () => {
        const filteredDrugNames = drugNames.filter((drug) => drug.trim() !== "");
        const filteredProtocolNumbers = protocolNumbers.filter((protocol) => protocol.trim() !== "");

        if (!filteredDrugNames.length && !filteredProtocolNumbers.length) {
            setError("Please enter at least one drug name or one protocol number.");
        } else {
            setLoading(true);  // Start the spinner

            setError("");

            // Initiate both API calls and wait for both to complete using Promise.all
            Promise.all([
                getSimilarProtocols({
                    protocol_numbers: filteredProtocolNumbers,
                    drug_names: filteredDrugNames,
                }),
                getRecommendedProtocol({
                    "protocol_numbers": filteredProtocolNumbers,
                    "drug_names": filteredDrugNames,
                    "Compound name/number": compoundName,
                    "Mechanism of Action (MOA)": moa,
                    "Leader contact information": leaderContact,
                    "Acronym/short name of trial": acronym,
                    "Protocol number": protocolNumber,
                    "IND number": indNumber,
                    "Phase 1/2/3": phase,
                    "Randomized": randomized,
                    "Randomization ratio": randomizedRatio,
                    "Population": population,
                    "Precise indication/disease state/condition/prior treatments": diseaseCondition,
                    "Blinding/open-label": blinding,
                    "US/Global/Multi-center": usGlobal,
                    "Number of arms": numArms,
                    "Comparator(s)": comparator,
                    "Length of cycle": lengthOfCycle,
                    "Number of cycles": numCycles,
                    "Estimated duration of Study": estDurationStudy,
                    "Estimated duration of intervention": estDurationIntervention,
                    "Stages": numStages,
                    "Approximate number of participants": approxParticipants,
                    "Primary Objectives": primaryObjectives,
                    "Secondary Objectives": secondaryObjectives,
                    "Generated Title": generatedTitle,
                    ...stageInputs,
                })
            ])
                .then(([similarResponse, recommendedResponse]) => {
                    // Handle the response for similar protocols
                    if (similarResponse.data.protocol_docs) {
                        if (similarResponse.data.protocol_docs.length === 0) {
                            setAlertStatus(true);
                        } else {
                            setSearchResults(similarResponse.data.protocol_docs); // Save search results

                            // Normalize the API response using the title map
                            setTransformedResponse(transformResponseKeys(similarResponse.data.protocol_docs));
                        }
                    }

                    if (recommendedResponse.data) {
                        setRecommendedResults(recommendedResponse.data)
                    }

                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setError("An error occurred while fetching data. Please try again.");
                })
                .finally(() => {
                    setLoading(false);  // Stop the spinner after both API calls are completed
                });
        }
    };

    const toggleEdit = (section) => {
        setIsEditable((prevState) => ({
            ...prevState,
            [selectedSection]: !prevState[selectedSection],
        }));

        // If we're turning off editing, save the updated content
        if (isEditable[selectedSection]) {
            const updatedContent = document.querySelector(".toDownload").innerHTML;
            setRecommendedResults((prevState) => ({
                ...prevState,
                [selectedSection.toLowerCase()]: updatedContent,
            }));
        }
    };

    const toggleCollapse = () => {
        setSideBarCollapsedCollapsed(!sideBarCollapsed); // Toggle the collapsed state
    };

    const truncateText = (text, maxLength) => {
        if (!text) return "";
        return text.length > maxLength
            ? `${text.substring(0, maxLength)}`
            : text;
    };

    const downloadAsWord = () => {
        let htmlString = "";
        protocolData.forEach((section, secIndex) => {
            // Append content from each section
            let sectionContent = recommendedResults[section.toLowerCase()];

            if (sectionContent === "not available") {
                htmlString += `<h1>${section}</h1>`;
                htmlString += `<p>No content available.</p>`;
            } else {
                // Append the section content to the htmlString
                htmlString += `<p>${sectionContent}</p>`;
            }
        });

        const paragraphs = [];

        // Create a temporary element to parse HTML
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        // Convert the HTML elements to docx elements
        Array.from(tempElement.childNodes).forEach((node) => {
            let paragraphChildren = [];

            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.nodeName === 'P') {
                    // Process <p> elements
                    Array.from(node.childNodes).forEach((child) => {
                        if (child.nodeType === Node.TEXT_NODE) {
                            paragraphChildren.push(new TextRun({
                                text: child.textContent,
                                size: 24, // Increase font size to 12pt (size = 2 * pt size)
                            }));
                        } else if (child.nodeName === 'STRONG' || child.nodeName === 'B') {
                            // Handle both <strong> and <b> elements
                            paragraphChildren.push(new TextRun({
                                text: child.textContent,
                                bold: true,
                                size: 24, // Bold text with increased font size
                            }));
                        } else if (child.nodeName === 'EM') {
                            // Handle <em> (italics)
                            paragraphChildren.push(new TextRun({
                                text: child.textContent,
                                italics: true,
                                size: 24, // Italic text with increased font size
                            }));
                        }
                    });

                    // Add the paragraph with spacing after it to the paragraphs array
                    if (paragraphChildren.length > 0) {
                        paragraphs.push(new Paragraph({
                            children: paragraphChildren,
                            spacing: {after: 200}, // Add spacing after each paragraph (200 Twips = 10pt)
                        }));
                    }
                } else if (node.nodeName === 'H1') {
                    // Process <h1> elements
                    const headingText = new TextRun({
                        text: node.textContent,
                        bold: true,
                        size: 48, // Size 48 (24pt) for heading
                    });

                    // Add <h1> as a new paragraph with a page break
                    paragraphs.push(new Paragraph({
                        children: [headingText],
                        heading: HeadingLevel.HEADING_1,
                        pageBreakBefore: true, // Forces a new page before this heading
                    }));
                }
            }
        });

        // Create a document with a single section and all paragraphs
        const doc = new Document({
            sections: [
                {
                    children: paragraphs,
                },
            ],
        });

        // Create a file blob and save
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, 'Recommended Protocol.docx'); // Use file-saver to download the file
        }).catch((error) => {
            console.error("Error while creating DOCX:", error);
        });

        setIsDropdownOpen(false); // Close dropdown after
    }

    const generatePdf = () => {
        let htmlString = "";
        protocolData.forEach((section, secIndex) => {
            // Append content from each section
            let sectionContent = recommendedResults[section.toLowerCase()];

            if (secIndex > 0) {
                htmlString += `<div style="page-break-before: always;">`;  // Add page break before every section after the first
            } else {
                htmlString += `<div>`;  // No page break before the first section
            }

            if (sectionContent === "not available") {
                htmlString += `<h1 style="margin: 0;">${section}</h1>`;
                htmlString += `<p>No content available.</p>`;
            } else {
                htmlString += `<p style="page-break-inside: avoid; margin: 0;">${sectionContent}</p>`;
            }

            // Close the div to encapsulate the section
            htmlString += `</div>`;

        });

        const options = {
            margin: 1,
            filename: 'document.pdf',
            jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
        };

        // Create a temporary div to hold the HTML string and convert it
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Convert the HTML string to PDF
        html2pdf().from(tempDiv).set(options).save();

        setIsDropdownOpen(false); // Close dropdown after
    }

    const downloadSingleSectionPDF = () => {
        const element = document.querySelector('.toDownload');
        html2pdf()
            .from(element)
            .set({
                filename: 'recommended-protocol.pdf',
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait' }
            }) 
            .save();
        setIsDropdownOpen(false); // Close dropdown after
    };

    return (
        <>
            {loading && (
                <div className="overlay">
                    <Spinner/>
                </div>
            )}

            {alertStatus && (
                <ReactJsAlert
                    status={alertStatus} // true or false
                    type={"warning"} // success, warning, error, info
                    title={"No such drug/protocol found!"}
                    Close={() => setAlertStatus(false)}
                />
            )}

            <div className="flex flex-col">
                {!searchResults && (
                    <>
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full max-w-5xl p-4">
                                <div className="text-center mt-5">
                                    <div className="header text-3xl font-bold">FASE</div>
                                </div>
                                <div className="w-full px-4 md:px-8">
                                    {/* Left Side - Drug Name and Protocol Number */}
                                    <div className="left-half">
                                        {/* Drug Name Input */}
                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Drug Name</label>
                                            </div>
                                            {drugNames.map((drug, index) => (
                                                <div className="flex space-x-2 mb-2" key={index}>
                                        <textarea
                                            placeholder="Enter drug name"
                                            className="border border-gray-400 p-2 rounded w-full"
                                            onChange={(e) => handleDrugChange(index, e)}
                                            value={drug}
                                            rows={1}
                                            style={{resize: "none", overflow: "hidden"}}
                                        />
                                                </div>
                                            ))}
                                            <button onClick={addDrugField} className="p-2">
                                                <i className="fa-solid fa-add fa-xl"></i>
                                            </button>
                                        </div>

                                        {/* Protocol Number Input */}
                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Protocol Number</label>
                                            </div>
                                            {protocolNumbers.map((protocol, index) => (
                                                <div className="flex space-x-2 mb-2" key={index}>
                                        <textarea
                                            placeholder="Enter protocol number"
                                            className="border border-gray-400 p-2 rounded w-full"
                                            onChange={(e) => handleProtocolChange(index, e)}
                                            value={protocol}
                                            rows={1}
                                            style={{resize: "none", overflow: "hidden"}}
                                        />
                                                </div>
                                            ))}
                                            <button onClick={addProtocolField} className="p-2">
                                                <i className="fa-solid fa-add fa-xl"></i>
                                            </button>
                                        </div>
                                    </div>

                                    {/* Right Side - Additional Fields */}
                                    <div className="right-half">
                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Compound Name/Number</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter compound name/number"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleCompoundNameChange}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Mechanism of Action (MOA)</label>
                                            </div>
                                            <textarea
                                                placeholder="Enter mechanism of action"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleMoaChange}
                                                rows={2}
                                                style={{resize: "none", overflow: "hidden"}}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Leader Contact Information</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter leader contact information"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleLeaderContactChange}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Acronym/Short Name of Trial</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter acronym/short name"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleAcronymChange}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Protocol Number</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter protocol number"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleGeneratedProtocolNumChange}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">IND Number</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter IND number"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleIndChange}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Phase 1/2/3</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter phase (1, 2, or 3)"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handlePhase}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Randomized</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Randomized (Yes/No)"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleRandomized}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Randomized Ratio</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Randomized Ratio"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleRandomizedRatio}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Population</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Population"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handlePopulation}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Precise indication/disease
                                                    state/condition/prior
                                                    treatments</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter disease state/condition/prior treatments"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleDiseaseCondition}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Blinding/open-label</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Blinding/open-label"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleOpenLabel}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">US/Global/Multi-center</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter (US/Global/Multi-center)"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleUsGlobal}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Number of arms</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Number of arms"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleNumberOfArms}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Comparator(s)</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Comparator(s)"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleComparator}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Length of cycle</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Length of cycle"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleLengthOfCycle}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Number of cycles</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter number of cycles"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleNumberOfCycles}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Estimated duration of Study</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter estimated duration of Study"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleEstimatedDurationOfStudy}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Estimated duration of
                                                    Intervention</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter estimated duration of Intervention"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleEstimatedDurationOfIntervention}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Stages</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter number of stages"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleNumStages}
                                            />
                                        </div>

                                        {Object.keys(stageInputs).map((stageKey, index) => (
                                            <div key={index} className="mb-4">
                                                <div className="flex items-center space-x-2 mb-2">
                                                    <label className="font-medium">Stage {index + 1}</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder={`Enter details for ${stageKey}`}
                                                    className="border border-gray-400 p-2 rounded w-full"
                                                    value={stageInputs[stageKey]}
                                                    onChange={(e) => handleStageChange(stageKey, e)}
                                                />
                                            </div>
                                        ))}

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Approximate Number of
                                                    Participants</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Approximate Number of Participants"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleApproximateNumberOfParticipants}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Primary Objectives</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Primary Objectives"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handlePrimaryObjectives}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Secondary Objectives</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Secondary Objectives"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleSecondaryObjectives}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <div className="flex items-center space-x-2 mb-2">
                                                <label className="font-medium">Generated Title</label>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Enter Generated Title"
                                                className="border border-gray-400 p-2 rounded w-full"
                                                onChange={handleGeneratedTitle}
                                            />
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                </div>

                                {error && <div className="text-red-500 mt-4">{error}</div>}
                            </div>
                            <div className="flex justify-center mb-7">
                                <Button onClick={handleSearch}>Generate Protocol</Button>
                            </div>
                        </div>

                    </>
                )}

                {searchResults && (
                    <>
                        <div className="flex h-screen max-h-screen">
                            <button
                                className="bg-[#23479F] rounded-l w-12 p-2 h-10 text-white mt-4 ml-1"
                                onClick={toggleCollapse}
                            >
                                {sideBarCollapsed ?
                                    <i className="fa-solid fa-arrow-right"></i> :
                                    <i className="fa-solid fa-arrow-left"></i>}
                            </button>
                            {/* Left Sidebar for Protocol Sections */}
                            <div
                                className={`flex-none bg-[#23479F] shadow-2xl mt-4 rounded-r overflow-y-auto transition-all duration-300 ${
                                    sideBarCollapsed ? 'w-0 p-0' : 'w-80 p-5 mr-4'
                                }`}
                            >

                                {!sideBarCollapsed && (
                                    <ul>
                                        {protocolData.map((section, index) => renderSection(section, index))}

                                    </ul>
                                )}
                            </div>

                            {/* Middle Sidebar for Tabs */}
                            {selectedSection && (
                                <div
                                    className={"flex-none mt-4 shadow-2xl bg-gray-50 transition-all duration-300 w-80 p-5"}
                                >
                                    <h2 className="text-xl font-bold mb-4">{selectedSection}</h2>

                                    {/* FDA Guidelines Button */}
                                    <div className="mb-3">
                                        <div
                                            className="cursor-pointer px-3 py-2 mb-2 rounded bg-[#1D3C86] text-white hover:bg-gray-300 hover:text-black"
                                            onClick={() => setShowFDAGuidelines(!showFDAGuidelines)}
                                        >
                                            FDA Guidelines
                                        </div>

                                        {/* FDA Guidelines Content */}
                                        {showFDAGuidelines && (
                                            <div
                                                className="p-4 mb-6 border border-gray-300 rounded bg-gray-100">
                                                <p className="text-sm text-gray-700">
                                                    <button
                                                        className="text-black text-base underline"
                                                        onClick={() =>
                                                            handleFDAGuidelinesDownload(selectedSection)
                                                        }
                                                    >
                                                        Click here to view the FDA guidelines
                                                    </button>
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    {/* Similar Protocols Button */}
                                    <div className="mb-4">
                                        <div
                                            className="cursor-pointer px-3 py-2 mb-2 rounded bg-[#1D3C86] text-white hover:bg-gray-300 hover:text-black"
                                            onClick={() => setShowSimilarProtocols(!showSimilarProtocols)}
                                        >
                                            Similar Protocols
                                        </div>

                                        {/* Similar Protocols Content */}
                                        {showSimilarProtocols && (
                                            <div className="h-[35rem] overflow-y-auto space-y-2 mb-2">
                                                {searchResults.map((result, index) => (
                                                    <div
                                                        key={index}
                                                        className="p-4 border border-gray-300 rounded bg-gray-100"
                                                    >
                                                        <h3 className="text-lg font-bold">
                                                            {result.drug_name}
                                                        </h3>
                                                        <p className="text-sm font-semibold text-gray-500 mb-2">
                                                            Protocol #{result.protocol_number}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            {truncateText(
                                                                result[
                                                                    selectedSection
                                                                        .toLowerCase()
                                                                        .replace(/trial/g, 'trail')
                                                                        .replace(
                                                                            /regularity/g,
                                                                            'Regularity'
                                                                        )
                                                                    ]?.content || 'No content available',
                                                                100
                                                            )}
                                                            ...
                                                            <p className="text-sm text-gray-700">
                                                                <button
                                                                    className="text-black text-base underline"
                                                                    onClick={() =>
                                                                        handleSimilarProtocolDownload()
                                                                    }
                                                                >
                                                                    Read more
                                                                </button>
                                                            </p>

                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Right Content Display for Recommended Protocols */}
                            <div className="flex-grow p-5 overflow-y-auto">
                                <div className="sticky top-0 flex justify-end space-x-2 ">
                                    <Button
                                        size="outline"
                                        onClick={() => toggleEdit(selectedSection)}
                                        className="px-2 py-1 bg-blue-500 text-white rounded"
                                    >
                                        {isEditable[selectedSection] ? "Save" : "Edit"}
                                    </Button>
                                    <div className="relative">
                                        <Button
                                            size="download"
                                            onClick={() => {
                                                downloadSingleSectionPDF()
                                            }}
                                            className="px-2 py-1 text-white rounded"
                                        >
                                            Download
                                        </Button>
                                    </div>
                                </div>
                                {selectedSection ? (
                                    <div>
                                        <h3 className="text-2xl font-bold mb-4">
                                            Recommended Protocol
                                        </h3>
                                        <div className="toDownload w-full p-2 rounded overflow-y-auto">
                                            <div>{
                                                transformedResponse[0]?.[selectedSection]?.content || "No content present."                                            }
                                            </div>
                                            {/*<div*/}
                                            {/*    contentEditable={isEditable[selectedSection]}*/}
                                            {/*    dangerouslySetInnerHTML={{*/}
                                            {/*        __html:*/}
                                            {/*            recommendedResults[selectedSection.toLowerCase()] ||*/}
                                            {/*            recommendedResults['protocol summary']*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-lg font-bold">
                                    Please select a protocol section to view details
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Search;
