export const protocolData = [
    {
        title: "Protocol Summary",
        subsections: [
            {title: "Protocol Synopsis"},
            {title: "Trial Schema"},
            {title: "Schedule of Activities"}
        ]
    },
    {
        title: "Introduction",
        subsections: [
            {title: "Purpose of Trial"},
            {title: "Summary of Benefits and Risks"},
        ]
    },
    {
        title: "Trial Objectives, Endpoints and Estimads",
        subsections: [
            {title: "{Primary/Secondary/Exploratory Objective + Associated Endpoint {and Estimand}"}
        ]
    },
    {
        title: "Trial Design",
        subsections: [
            {
                title: "Description of Trial Design",
                subsections: [
                    {title: "Participant Input into Design"}
                ]
            },
            {
                title: "Rationale for Trial Design",
                subsections: [
                    {title: "Rationale for Comparator"},
                    {title: "Rationale for Adaptive or Novel Trial Design"},
                    {title: "Other Trial Design Considerations"},
                ]
            },
            {title: "Access to Trial Intervention After End of Trial"},
            {title: "Start of Trial and End of Trial"},
        ]
    },
    {
        title: "Trial Population",
        subsections: [
            {title: "Selection of Trial Population"},
            {title: "Rationale of Trial Population"},
            {title: "Inclusion Criteria"},
            {title: "Exclusion Criteria"},
            {
                title: "Lifestyle Considerations",
                subsections: [
                    {title: "Meals and Dietary Restrictions"},
                    {title: "Caffeine, Alcohol, Tobacco, and Other Habits"},
                    {title: "Physical Activity"},
                    {title: "Other Activity"},
                ]
            },
            {title: "Screen Failures"}
        ]
    },
    {
        title: "Trial Intervention and Concomitant Therapy",
        subsections: [
            {title: "Description of Trial Intervention"},
            {title: "Rationale for Trial Intervention"},
            {
                title: "Dosing and Administration",
                subsections: [
                    {title: "Trial Intervention Dose Modification"}
                ]
            },
            {title: "Treatment of Overdose"},
            {
                title: "Preparation, Handling, Storage and Accountability",
                subsections: [
                    {title: "Preparation of Trial Intervention"},
                    {title: "Handling and Storage of Trial Intervention"},
                    {title: "Accountability of Trial Intervention"},
                ]
            },
            {
                title: "Participant Assignment, Randomisation and Blinding",
                subsections: [
                    {title: "Participant Assignment"},
                    {title: "Randomisation"},
                    {title: "Blinding and Unblinding"},
                ]
            },
            {title: "Trial Intervention Compliance"},
            {
                title: "Concomitant Therapy",
                subsections: [
                    {title: "Prohibited Concomitant Therapy"},
                    {title: "Permitted Concomitant Therapy"},
                    {title: "Rescue Therapy"},
                    {title: "Other Therapy"},
                ]
            },
        ]
    },
    {
        title: "Discontinuation of Trial Intervention and Participant Withdrawal from Trial",
        subsections: [
            {
                title: "Discontinuation from Trial intervention",
                subsections: [
                    {title: "Criteria for Permanent Discontinuation of Trial Intervention"},
                    {title: "Temporary Discontinuation or Interruption of Trial Intervention"},
                    {title: "Rechallenge"},
                ]
            },
            {title: "Participant Withdrawal from the Trial"},
            {title: "Lost to Follow-Up"},
            {title: "Trial Stopping Rules"},
        ]
    },
    {
        title: "Trial Assessments and Procedures",
        subsections: [
            {title: "Screening/Baseline Assessments and Procedures"},
            {title: "Efficacy Assessments and Procedures"},
            {
                title: "Safety Assessments and Procedures",
                subsections: [
                    {title: "Physical Examination"},
                    {title: "Vital Signs"},
                    {title: "Electrocardiograms"},
                    {title: "Clinical Laboratory Assessments"},
                    {title: "Suicidal Ideation and Behaviour Risk Monitoring"},
                ]
            },
            {
                title: "Adverse Event and Serious Adverse Events",
                subsections: [
                    {title: "Definitions of AE and SAE"},
                    {title: "Time Period and Frequency for Collection AE and SAE Information"},
                    {title: "Identifying AEs and SAEs"},
                    {title: "Recording of AEs and SAEs"},
                    {title: "Follow-up of AEs and SAEs"},
                    {title: "Reporting of SAEs"},
                    {title: "Regulatory Reporting Requirements for SAEs"},
                    {title: "Serious and Unexpected Adverse Reaction Reporting"},
                    {title: "Adverse Events of Special Interest"},
                    {title: "Disease-related Events or Outcomes Not Qualifying as AEs or SAEs"},
                ]
            },
            {
                title: "Pregnancy and Postpartum Information",
                subsections: [
                    {title: "Participants Who Become Pregnant During the Trial"},
                    {title: "Participants Whose Partners Become Pregnant"},
                ]
            },
            {
                title: "Medical Device Product Complaints for Drug/Device Combination Products",
                subsections: [
                    {title: "Definition of Medical Device Product Complaints"},
                    {title: "Recording of Medical Device Product Complaints"},
                    {title: "Time Period and Frequency for Collecting Medical Device Product Complaints"},
                    {title: "Follow-Up of Medical Device Product Complaints"},
                    {title: "Regulatory Reporting Requirements for Medical Device Product Complaints"},
                ]
            },
            {title: "Pharmacokinetics"},
            {title: "Genetics"},
            {title: "Biomarkers"},
            {title: "Immunogenicity Assessments"},
            {title: "Medical Resource Utilisation and Health Economies"},
        ]
    },
    {
        title: "Statistical Considerations",
        subsections: [
            {title: "Analysis sets"},
            {
                title: "Analyses Supporting Primary Objective(s)",
                subsections: [
                    {title: "Statistical Model, Hypothesis, and Method of Analysis"},
                    {title: "Handling of Intercurrent Events of Primary Estimand(s)"},
                    {title: "Handling of Missing Data"},
                    {title: "Sensitivity Analysis"},
                    {title: "Supplementary Analysis"},
                ]
            },
            {title: "Analysis Supporting Secondary Objective(s)"},
            {title: "Analysis of Exploratory Objective(s)"},
            {title: "Safety Analyses"},
            {title: "Other Analyses"},
            {title: "Interim Analyses"},
            {title: "Sample Size Determination"},
            {title: "Protocol Deviations"},
        ]
    },
    {
        title: "General Considerations: Regulatory, Ethical, and Trial Oversight",
        subsections: [
            {title: "Regulatory and Ethical Considerations"},
            {title: "Committees"},
            {title: "Informed Consent Process"},
            {title: "Data Protection"},
            {title: "Early Site Closure or Trial Termination"},
        ]
    },
    {
        title: "General Considerations: Risk Management and Quality Assurance",
        subsections: [
            {title: "Quality Tolerance Limits"},
            {title: "Data Quality Assurance"},
            {title: "Source Data"},
        ]
    },
    {
        title: "Appendix: Adverse Events and Serious Adverse Events – Definitions, Severity, and Causality",
        subsections: [
            {title: "Further Details and Clarifications on the AE Definition"},
            {title: "Further Details and Clarifications on the SAE Definition"},
            {title: "Severity"},
            {title: "Causality"},
        ]
    }
];